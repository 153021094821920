.iserach-section-one{
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: url("../assets/Images/HomeBanner4.png");
    background-size: cover;
}

.customized{
  color: #fff;
}
.home-hero--header{
    margin-top: 6.7rem;
}
.diff-design{
    background-color: #134372;
    height: 100%;
    width: 100%;
}

.home-hero--header h1{
    font-size:59px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #fff;
    font-family: "Avenir Next LT Pro", sans-serif;
}

.home-hero--desc{
    margin-bottom: 3rem;
    color: #fff!important;
    font-size: 16px;
    font-family: "Avenir Next LT Pro", sans-serif;
}

/* Banner Sections */
.banner-section{
    background-color: #ffffff;
    padding-top: 2.5rem;
    padding-bottom: 4rem;
}

.Exxon-banner-head{
    padding-top:3rem;
    padding-bottom: 1.5rem;
    text-align: center;
    font-size: 38px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 2.25rem;
    color: #003d47;
}

.Exxon-banner-para{
    font: 14px;
    margin-bottom: 1rem;
    text-align: center;
}

.Exxon-banner-table{
    display: flex;
    flex-wrap: wrap; 
}
.Exxon-banner-col{
    font-size: 15px;
    flex: 0 0 25%;
    max-width: 25%;
    background-color: #022F4D;
    color: #ffffff;
    font-weight: 600;
    border-bottom: 1px solid wheat;
    border-right: 1px solid wheat;
    text-align: center;
    padding: 15px 7.5px;
    cursor: pointer;
}

.Exxon-banner-col span{
    cursor: pointer;
}

.Exxon-banner-col span:hover{
  color: lightgrey !important;
  transform: translateX(5px);
}

.Exxon-banner-list{
    padding-left: 15px;
    margin-top: 2.25rem;
}

.Exxon-banner-list li{
    font-size: 15px;
    margin-bottom: 0.85rem;
    line-height: 24px;
}


/* PopUp Css Code */

.Exxon-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 1000;
    max-width: 492px;
    width: 50%;
    z-index: 1001;
  }

  .Exxon-popup-container h2{
    color: #022F4D;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
  }
  .Exxon-popup-container p{
    font-size: 14px;
    font-weight: 600;
    color: #022F4D;
    text-align: center;
    margin-bottom: 1rem;
  }
  .Exxon-Engagement{
    margin-bottom: 25px;
  }
  .Exxon-joining{
    margin-bottom: 17px;
  }
  .Exxon-Exprience{
    margin-bottom: 17px;
  }

  .Engagement-timeline{
    margin-bottom: 0.3rem;
    color: #55B2BE;
    font-weight: 600;
    font-size: 15px;
    display: block;
  }

  .Exxon-Engagement{
    margin-bottom: 25px;
  }

  .radio-but{
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
  }

  .Exxon-Engagement .radio-but{
     font-size: 14px;
     font-weight: 500;
     display: inline-flex;
     align-items: center;
  }
  .radio-but span{
    line-height: 20px;
    margin-left: 7px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .radio-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .medium-input{
    border: 1px solid #dfdfdf;
    color: #828282;
    outline: 0;
    resize: none;
    width: 100%;
    font-size: 14px;
    height: 49px;
    max-width: 100%;
    padding: 15px 20px!important;
  }

  .phone-input{
    border: 1px solid #dfdfdf;
    color: #828282;
    outline: 0;
    resize: none;
    width: 100%;
    font-size: 14px;
    height: 49px;
    max-width: 100%;
    padding: 15px 20px 15px 50px !important; 
    box-sizing: border-box; 
  }

  .medium-textarea{
    border: 1px solid #dfdfdf;
    color: #828282;
    outline: 0;
    resize: none;
    width: 100%;
    height: 73px;
    line-height: normal;
    margin: 0 0 11px;
    max-width: 100%;
    padding: 10px 15px;
  }
  .Exxon-Contactus{
    row-gap: 16px;
  }

  .Exxon-Submit{
    width: 100%;
    background-color: #EFAF01;
    color: #000000;
    font-weight: 700;
    border-radius: 3px;
    max-width: 325px;
    display: block;
    margin: 0 auto;
    padding: 0.75rem;
    border: none; 
  }
  .Exxon-Radio{
    width: 16px;
    height: 16px;
  }
  .Exxon-Submit:hover{
    background-color: skyblue;
    transition: 0.2s ease-in-out;
  }
 
 .Popup-Header{
  align-self: flex-end;
  margin-right: 10px;
  cursor: pointer;
  font-size: 25px;
 }
 .Popup-Header:hover{
    color: red;
    font-weight: bold;
 }
 .Exxon-Close-Button{
    display: flex; 
 }

 /* About Us Page Css */
.Exxon-About{
  background-color: #022F4D;
}

 .Exxon-Aboutrow{
  padding-top: 10rem;
  padding-bottom: 7.2rem;
 }

 .Exxon-About h2{
  color: white;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 2.4rem;
 }

 .Exxon-About p{
  color: #fff;
 }

 .Exxon-About p a{
  color: #fff;
  text-decoration: none;
 }

 /* What we Do Page css */
 .Exxon-wedo{
  background-color: #eeeeee;
 }
 .Exxon-Wedorow{
  padding-top: 8rem;
  padding-bottom: 6.2rem;
}

 .Wedo-img{
  width: 100%;
  border-radius: 20px;
  height: auto;
 }
 .Whatwedo-hiring h3{
  color: black;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 1.4rem;
 }

 /* Technical Expeties */
 .Exxon-Technical{
  background-color: #022F4D;
  min-height: 88vh;
 }

 .Exxon-Provide-header {
    padding: 4rem 0 2rem;
    text-align: center !important;
    color: #fff !important;
    font-size: 50px !important;
    line-height: 40px !important;
 }
 .WeProvide-Exxon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  margin: 20px;
  border-radius: 20px;
  
  text-align: center;
  transition: all .4s ease 0s;
  cursor: pointer;
  height: 80%;
  width: 100%;
 }

 .WeProvide-Exxon:hover {
  box-shadow: 0 0 100px rgba(28, 36, 68, 0.1);
  background-color: #fff;
  transform: translateY(-10px);
}
 .ex-head{
  text-align: center;
  color: gray;
  font-size: 22px;
  margin: 15px 8px;
  font-weight: 700;
}
.WeProvide-Exxon img {
    width: 40%;
    height: auto;
    margin: 15px 8px;
}
.WeProvide-Exxon p{
    text-align: center;
    color: #1C2444;
    font-size: 17px;
    line-height: 30px;
}
.ExxonPeople{
  display: flex;
  align-items: center;
}
 
@media (min-width: 992px){
.offset-lg-2{
 margin-left: 10% !important;
}
}

 /* Media query for Home Page */
 @media(max-width:768px){
     .home-hero--header h1{
      font-size: 36px;
      margin-bottom: 27px;
      font-weight: 600;
      text-align: center;
      color: #fff;
     }
     .WeProvide-Exxon {
      margin: 1px;
      height: 89%;
     }

     .home-hero--desc{
      margin-bottom: 3rem;
      color: #fff!important;
      font-size: 14px;
     }

     .Exxon-banner-head{
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      text-align: center;
      font-size: 27px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 1.25rem;
      color: #003d47;
     }

     .Exxon-About h2{
      color: white;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 2.4rem;
      margin-top: -80px;
     }

    .Exxon-hiring-indurstry{
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
     }
     
    .Exxon-Wedorow{
      padding-top: 50px;
    }
    .Technical-Specilization {
      padding-top: 3.6rem;
    }
    .Technical-Specilization h3 {
      font-size: 1.78rem;
    }
    .Exxon-WhyUs {
      padding-top: 3.3rem !important;
    }
    .Exxon-WhyUs h2{
      font-size: 2.2rem !important;
      margin-bottom: 1.3rem !important;
    }

    .Exxon-WhyUs p{
      margin-bottom: 2.4rem;
    }
    .Exxon-popup-container{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 8px;
      border: 1px solid #ccc;
      z-index: 1000;
      max-width: 400px;
      width: 79%;
      z-index: 1001;
      height: 102vh;
    }
    .Exxon-Contactus {
      row-gap: 2px;
    }
    .Exxon-Exprience {
      margin-bottom: 3px;
    }
    .medium-textarea {
      margin: 0 0 0px;
    }
    .Exxon-Engagement {
      margin-bottom: 10px;
    }
    .Exxon-joining {
      margin-bottom: 3px;
    }
    .Exxon-popup-container p {
      margin-bottom: 0.5rem;
    }
 }