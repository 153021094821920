.blogs-section-one{
    min-height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #010920;
    background-size: cover;
}

.ExxonBlogTitle{
    color: #FFF;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ExxonBlogPara{
    color: #FFF;
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-sectin-two{
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.Exxonbox{
    border-radius: 9px;
}
.Exxonbox:hover{
    box-shadow: 0px 0px 23px #00000047;
    border-color: transparent;
    cursor: pointer;
}
.Exxonblog-img{
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.Exxon-blogbody{
    min-height: 175px;
    padding: 1.25rem;
}
.Exxoncard-date{
    font-size: 16px;
    letter-spacing: 0.2em;
    margin-bottom: 1rem;
}
.ExxonCardinner{
    font-size: 20px;
    color: #072267;
    font-weight: bold;
}

@media(max-width:768px){
    .ExxonBlogPara {
        text-align: center;
    }
    .exxonblog-content h1{
        text-align: center !important;
        font-size: 24px !important;
    }
    .ExxonBlogdate{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0 !important;
    }
    .blog-content-wrapper h2 {
        font-size: 23px !important;
    }
    .blog-content-wrapper {
        padding: 0%  5% 0% 5% !important;
    }

}
