body {
  margin: 0;
  font-family: "Avenir Next LT Pro", sans-serif;
  user-select: none;
}

.some-specific-element {
  user-select: text;
}


