.Why-Exxon-Section{
    background-color: #f9f9f9;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.Exxon-left-side h3{
    position: relative;
    font-size: 1.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 1.4rem;
    font-weight: 600;
    color: #0B334D; 
}
.Exxon-left-side h3::after{
    position: absolute;
    content: '';
    width: 117px;
    border-radius: 2px;
    height: 4px;
    background-color: blue;
    bottom: 0rem;
    left: 0;
}

.Exxon-left-side p{
    padding-left: 2rem !important;
    background-size: 15px;
    margin-bottom: 1rem;
    background-image: url(../../assets/Images/RightArrow.png);
    background-repeat: no-repeat;
    background-position: left center; 
    font-size: 19px;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
}

.Exxon-Why{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}
.Exxon-head-Process {
    position: relative;
    font-size: 1.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 1.4rem;
    font-weight: 600;
    color: #0B334D;
}

.Exxon-head-Process::after
  {
    position: absolute;
    content: '';
    width: 117px;
    border-radius: 2px;
    height: 4px;
    background-color: blue;
    bottom: 0rem;
    left: 0;
}

.Exxon-right-side > p{
    padding-left: 3.15rem;
    margin-bottom: 1rem;
    min-height: 36px;
    line-height: 22px;
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 500;
    color: #000000;
}

.Exxon-right-side > p.Exxon-details {
    background-image: url("../../assets/Images/Send.png");
    background-repeat: no-repeat;
    background-size: 36px; 
    background-position: left center; 
    padding-left: 50px; 
    margin-bottom: 1.5rem;
}

.Exxon-right-side > p.Exxon-select{
    background-image: url("../../assets/Images/select.png");
    background-repeat: no-repeat;
    background-size: 36px; 
    background-position: left center; 
    padding-left: 50px;
    margin-bottom: 1.5rem;
}

.Exxon-right-side > p.Exxon-Interview{
    background-image: url("../../assets/Images/interview.png");
    background-repeat: no-repeat;
    background-size: 36px; 
    background-position: left center; 
    padding-left: 50px;
    margin-bottom: 1.5rem;
}

.Exxon-right-side > p.Exxon-assign {
    background-image: url("../../assets//Images/initialproject.png");
    background-repeat: no-repeat;
    background-size: 36px; 
    background-position: left center; 
    padding-left: 50px; 
    margin-bottom: 1.5rem;
}
.Exxon-hire{
    font-size: 1.9rem;
    padding-bottom: 0.8rem;
    margin-bottom: 3rem;
    text-align: center;
    font-weight: 600;
    color: #0B334D;
}

