.blog-section-two{
    min-height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../assets/Images/B5.png") !important;
    background-size: cover;
}
.Exxonoverviewtwo{
    padding-top: 110px;
}
.HireDev2{
  height:100%;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
@media(max-width:768px){
  .exxonblogimg{
    width: 100%;
  }
}