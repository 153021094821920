.whatsapp-button{
    position: fixed;
    bottom: 10px;
    left:30px;
    cursor: pointer;
}

.whatsapp-logo {
    width: 40px; 
    height: 40px;
  }