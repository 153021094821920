.blog-section-three{
    min-height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../assets/Images/BlogBanner3.svg") !important;
    background-size: cover;
}

.Exxonoverviewthree{
    padding-top: 110px;
}