
 /* Exxon Client Section CSS */
 .Exxon-Client-Section{
    padding-bottom: 60px;
    padding-top: 60px;
 }
 .Exxon-cleint{
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .Exxon-cleint p{
  padding: 0 0 0 16px;
  align-self: center;
  color: black;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 0;
 }

 .Exxon-Global-Client{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
 }
 .Exxon-Global-Client h4{
  font-weight: 750;
  font-size: 48px;
  line-height: 120%;
  color: #21aae4;
 }
 .Exxon-Global-Client .sign{
  font-weight: 750;
  font-size: 48px;
  line-height: 120%;
  color: #21aae4;
  margin-bottom: 11px;
 }
 .Exxon-Global-Client p{
  padding: 0 0 0 16px;
  align-self: center;
  color: black;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
 }