#chatbox {
    width: 350px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  #chat {
    padding: 15px;
    height: 400px;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .Echatbot{
    height: 9vh;
  }
  
  .message-container {
    margin-bottom: 10px;
  }
  
  .user-message, .chatbot-response {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    word-wrap: break-word;
    margin-right: 10px;
  }
  
  .user-message {
    background-color: #6E48AA;
    color: #fff;
    align-self: flex-end;
  }
  .Exxonsubtext{
   font-size: 14px;
   margin-top: 1rem;
  }
  .welcome-box h3{
    font-size: 22px !important;
    font-weight: 600;
  
  }
  
  .chatbot-response {
    background-color: #6E48AA;
    color: #333;
    align-self: flex-start;
  }
  
  #input-box {
    display: flex;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  #user-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    outline: none;
  }
  
  #send-btn {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #send-btn:hover {
    background-color: #0056b3;
  }
  
  .button button {
     border-radius: 50%;
    background-color: rgb(0, 164, 189) !important;
    color: white;
    position: fixed;
    bottom: 3%; 
    right: 3%;
    z-index: 1000;
    width: 65px;
    height: 65px;
  }
  .button button:hover {
      background-color: rgb(2, 62, 71) !important;  
    }
  .chat-btn button {
  background-color: #CCDFDB;
  color: black;

  font-size: 16px; 
  width: 100%;
  }
  
  .chat-btn button:hover {
  background-color: darkgreen; 
  color: white;
  }
  
  .offcanvas {
  height: 58%;
  margin-top: 19%;
  margin-right: 1%;
  border-radius: 15px;
  }
  .offcanvas-header img{
  border: none;
  }
  
  .text p{
    font-size: 12px;
  }
  
  
  .chat-msg{
    background-color: #6E48AA;
    color: #fff;
      padding: 10px 20px; 
      border-radius: 7px;
  }
  
  #exxon-logo {
    width: 40px; 
    height: 40px; 
    margin-right: 5px; 
    vertical-align: middle;
    border: none;
    background: none;
  }
  
  .offcanvas-header{
    background-color: #010920;
  }
  .offcanvas-header img{
    background: none;
  }
  
  
  
  @media only screen and (max-width: 768px) {
    #chatbox {
      width: 100%;
    }
  
    #chat {
      height: 250px; 
    }
  
    .message-container {
      max-width: 100%;
    }
  
    .user-message,
    .chatbot-response {
      max-width: 100%;
    }
  
    #input-box {
      padding: 10px;
    }
  
    #user-input {
      flex: 1;
      padding: 8px;
      margin-right: 5px;
    }
  
    #send-btn {
      padding: 8px 12px;
      font-size: 14px;
    }
  
    .button button {
      bottom: 8px;
      right: 8px;
    }
  
    .offcanvas-header img{
      background: none;
      height: 100%;
    }
    .offcanvas{
      height: 70%;
      margin-top: 40%;
      margin-right: 0.8%;
      border-radius: 15px;
    }
   
  }