/* Header Styles */

#isearch-header.isearch-room {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: 0.3s;
  padding: 20px 0;

}

#isearch-header.isearch-room.is-sticky {
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
}

/* Original styles for ul and li */
.isearch-room .navbar-nav .nav-item a {
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  margin: 0 14px;
  color: white;
  transition: color 0.3s ease;
  cursor: pointer;
}

.isearch-room.is-sticky .navbar-nav .nav-item a {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.isearch-room.is-sticky {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
}

/* New css */
.isearch-secondary {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
}

.isearch-secondary a {
  text-decoration: none;
  color: #fff;
  display: block;
  font-weight: 500;
  padding: 16px 24px;
  border-radius: 32px;
}

.hare-talent {
  text-decoration: none;
  color: #fff;
  display: block;
  font-weight: 500;
  border-radius: 32px;
}

.hare-talent a:hover {
  color: #fff;
  cursor: pointer;
}

.hare-talent a {
  background-color: #1179a8;
  padding: 16px 32px;
}

.ExxonHeader-Call {
  margin-left: 13px;
}

.ExxonHeader-Call {
  text-align: center;
  margin-bottom: 1.5rem;
  color: black;
  font-size: 20px;
  font-weight: 700 !important;
}

/* Mobile size button Responsiveness  Css Code*/
@media (max-width: 767px) {
  #navbarSupportedContent {
    position: sticky;
    top: 0;
    width: 100%;
    min-height: 34rem;
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 4rem;
    padding: 4rem 0;
    border-bottom: 1px solid #49666a;
    background-color: #fff;
  }
}

@media (max-width: 767px) {

  .isearch-room:not(.is-sticky) .navbar-brand img {
    filter: none;
  }

  .isearch-room:not(.is-sticky) .navbar-brand.custom-logo img {
    display: block;
  }


  .isearch-room .navbar-nav .nav-item a {
    color: black;
    font-weight: 700;
    font-size: 20px;
  }

  .navbar-nav .nav-item {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .isearch-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .isearch-room.is-sticky .navbar-nav .nav-item a {
    font-weight: 700;
  }
}


@media (max-width: 768px) {
  .navbar-collapse {
    display: none;
  }

  .navbar-collapse.open {
    display: block;
  }
}