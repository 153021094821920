/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .contact-card {
        width: 100%;
        /* Make the card full width */
    }

    .sendMessageForm {
        padding: 8px;
        /* Adjust padding */
    }

    /* Adjust input sizes */
    .medium-input {
        width: 100%;
    }

    .medium-textarea {
        width: 100%;
    }
}