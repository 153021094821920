.Exxon-Footer{
    margin-top: 4rem;
    background-color: #122f45;
    color: #fff;
    padding: 5rem;
}
.ExxonLogoImg{
    height: auto;
    max-width: 40%;
    margin-bottom: 39px;
}
.Exxon-Contact{
    margin-left: 15px;
}

.Exxon-FooterRow h4{
    font-size: 20px;
    margin-bottom: 1.25rem;
}

.Exxon-Call a{
    color: #ffffff;
    text-decoration: none;
    margin-left: 10px;
}
.Exxon-ReactOut{
    background-color: #EFAF01;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    padding: 10px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0;
    width: 167px !important;
    text-decoration: none;
    margin-top: 10px;
    margin-left: 9px;
}

.ExxonFooter-Copyrght{
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
}

.start-exon{
    height: 20px;
}

.Exxon-left-footer{
    margin-top: 27px;
    margin-bottom: 20px;
}

.exxon-footer-email{
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}
.ExxonFlag{
  margin-right:22px;
  margin-top:-9px;
  margin-left:17px;
}

/* New Footer */
.ExxonFooter-header{
    border-bottom: 1px solid;
    width: fit-content;
    white-space: nowrap;
    font-size: 30px;
}

.Exonfoter-link{
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    transition: all .5s;
}

.footerhr{
    height: 4px;
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25;
}
.country-logo{
    width: 60%;
    height: auto; 
}
.Exxon-Numberrow{
    margin-bottom: 10px;
}

.Exxon-SocialLink{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:10px;
}

.Exxon-SocialLink img{
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.email-logo{
    height: 18px;
    width: 18px;
    margin-right: 5px;
}
.email-anch{
    color: #fff;
    text-decoration: none;
}

@media(max-width:768px){
    .Exxon-WhyUs {
        padding-bottom: 1.8rem !important;
    }
    .Exxon-Footer{
        padding: 2rem;
    }
    .Exxon-FooterRow h4{
        margin-top: 15px;
    }

    .country-logo {
        width: 45%;
        height: auto;
    }
    .start-exon {
        margin-left: -128px;
    }
    .Exxonc{
        margin-bottom: 25px;
    }
}

.ExxonUnderImg{
    height: 30px !important;
}

.ExxonUperpart{
    margin-bottom:10px !important;
    width: 80% !important;
}

@media(max-width: 1196px) {
    .Exxon-Call 
    {
        font-size:10px !important; 
        width: 100%;
    }
}