
.ExxonError{
    min-height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #010920 !important;
    background-size: cover;
    margin-top: 50px;
}

.page_404 {
    padding: 93px 0;
    background: #fff;
    font-family: "Arvo", serif;
    
  }
  
  .page_404 img {
    width: 100%;
  }
  
  .four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    width: 100%;
    background-position: center;
  }
  
  .four_zero_four_bg h1 {
    font-size: 80px;
  }
  
  .four_zero_four_bg h3 {
    font-size: 80px;
  }
  
  
  .contant_box_404 {
    margin-top: -50px;
  }