.TechnologySection{
    padding-bottom: 5%;
    padding-top: 2%;
}

.technologysecion-head{
    color: #232323;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 27px;
    line-height: 40px;
}

.Exxon-header-border{
    width: 10%;
    border-bottom: 2px solid #e2666a;
    margin: 0 auto;
    padding-top: 10px;
}

.Exxon-Tech-Main{
    margin-top: 50px;
    margin-bottom: 40px;
}

.ExxonImg{
    max-width: 100%;
    height: 100px !important;
    padding-left: 3%;
    padding-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
}