.blog-oneSection{
    min-height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../assets/Images/B4.png") !important;
    background-size: cover;
   }
.exxonblog-content{
    margin-top: 20px;
}
.ExxonblogCategory{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: .04em;
    color: #F48220;
    margin: 0 0 10px 0;
    text-align: center;
}
.exxonblog-content h1{
    color: #fff;
    text-align: left;
    font-size: 34px;
}

.ExxonoverviewSection{
    padding-top: 110px;
}
.blog-content-wrapper{
    padding: 0% 15% 0% 15%;
}
.blog-content-wrapper h2{
   font-size: 25px;
   font-weight: 700;
   color: #192734;
   padding: 20px 0 5px 0;
}
.blog-content-wrapper p{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #4C5A67;
    margin: 0 0 15px 0;
}
.HireDev{
  height:100%;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}
.ExxonBlogdate{
    margin-top: 40px !important;
    display: flex;
    margin-left: 20px;
}
.ExxonBlogdate span{
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #fff;
    margin-left: 5px;
}
