.blog-fiveSection {
    min-height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../assets//Images//Blog5Banner.png") !important;
    background-size: cover;
}

.ExxblogLogo {
    width: 100%;
    height: auto;
}