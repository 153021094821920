.Exxon-WhyUs{
    padding-top: 4.3rem;
    padding-bottom: 2.8rem;
}
.Exxon-WhyUs h2{
    margin-bottom:2.3rem;
    font-size: 3.2rem;
    font-weight: 700;
}

.Exxon-WhyUs p{
    text-align: center;
    margin-bottom: 4.4rem;
}


/* Why choose Us ? CSS */

.Exxon-Oppening-Item{
    border: 2px solid #eee;
    border-radius: 2.5rem 2.5rem 2.5rem;
    padding: 2.8rem 2.6rem;
    height: 90%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.Exxon-Oppening-Item svg{
    margin-bottom: 20px;
}
.Exxon-Oppening-Item p{
    color: black;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 400;  
    text-align: left; 
}


/* Media Query Sections */
@media(max-width:768px){
    .Exxon-ChooseUs-row{
        row-gap: 23px;
    }
}